<template>
  <div class="news4">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="18" style="margin-bottom: 56px">
          <div style="background-color: #fff">
            <div class="news4__title-container">
              <span>首页 > </span>
              <span>新闻资讯 > </span>
              <span>资讯详情</span>
            </div>
            <div class="news4__content-left">
              <div class="news4-title">谈谈新能源连接器市场中连接器的发展趋势</div>
              <div class="news4-createTime">
                <span>发布时间： 2022/01/15 15:47:13</span>
                <span>浏览： 68次</span>
              </div>
              <div style="font-size: 16px;padding-bottom: 64px;border-bottom: 1px solid #d4d4d4;padding: 0 20px;">
				<div  style="text-align: center;margin: 40px 0;">
				   <img style="width: 100%;" src="./2.png" alt="">
				</div>
				<div class="news4-content">
				       伴随着时代的进步，电子技术的快速发展，电子元器件中的连接器产品在汽车行业、医疗行业、智能家居行业以及微电子行业越来越受到人们的重视，因为这些电子产品都是和我们日常生活中息息相关。而如今的元器件越来越小，电路密度越来越高，传输速度越来越快这是电子产品未来的发展趋势，这些也将成为连接器行业新的增长点。
				</div>
				<div class="news4-content">
					在汽车市场中，新能源汽车逐渐成为了主流，除了在电池、电机、电控等关键技术取得突破外，包括连接器在内的核心电子元器件都要有所作为，而不能像传统汽车领域中那样沦为“配角”，我们就在此分析一下，如何设计连接器才能占领新能源汽车的连接器市场。
				</div>
				<div class="news4-content">
					       由于新能源汽车是绿色环保的汽车，因而元器件中的连接器也要求绿色环保。无论是元器件的生产制作还是实际运用中，使用绿色材料，避免污染，降低能耗都成为连接器厂家必须考虑的因素，而这些绿色特征，也会成为产品的市场优势。新能源汽车中的连接器作为其中的重要代表，伴随着新能源汽车的崛起自然也经历着巨大的改变。因此、连接器在未来的新能源汽车市场将向着安全化、绿色化、多样化、模块化等四个方向发展，下文主要来讲解这四个发展方向；
				</div>
				<div class="tiles"  style="font-weight: bold;">
					安全化：
				</div>
				<div class="news4-content">
					       新能源汽车连接器要承受更高电流与电压，而连接器在工作过程中更是要承受高强度震动、高温、水雾以及灰尘等恶劣环境，因此连接器的安全性必然是人们关注的重中之重。
				</div>
				
				<div class="tiles"   style="font-weight: bold;">
					绿色化：
				</div>
				<div class="news4-content">
					       新能源汽车之所以发展势头如此迅猛，正得益于人们对环保的日益关注与重视，未来人们对连接器的材料选择也会愈加苛刻，对人体、对环境的危害力求最小。
				</div>
				<div class="tiles"   style="font-weight: bold;">
					多样化：
				</div>
				<div class="news4-content">
					       新能源汽车线束的布局的多样化，对连接器的结构、安装空间、尺寸等将会有更多样化的需求。
				</div>
				<div  class="tiles"   style="font-weight: bold;">
					模块化：
				</div>
				<div class="news4-content">
					       连接器逐渐走向模块化设计，快速满足客户的多样化需求。因此、我们有理由相信，连接器在未来市场中一定会前景广阔，大放异彩。
				</div>
             
              </div>

              <div style="padding-top: 20px;padding-bottom: 40px">
               <!-- <router-link to="/news4"> -->
                  <span style="color: #cccc;font-size: 16px">< 上一篇</span>
                <!-- </router-link> -->
				<router-link to="/news5">
				 <span class="fr" style="color: #DA261D;font-size: 16px">下一篇 ></span>
				</router-link>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="background-color: #fff;padding: 24px">
            <div style="font-size: 20px;font-weight: bold">热点资讯</div>
            <div style="border-bottom: 1px solid #D4D4D4">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验有哪些标准</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验SMT对于电子行……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">什么是PCBA代工代料，PCBA……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.news4 {
  background: #F6F6F6;

  .container {
    margin-top: 24px;
    padding: 0 gap();
  }
}

.news4__title-container {
  font-size: 14px;
  padding-top: 16px;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: #fff;

  span:nth-of-type(3) {
    color: #a6a6a6;
  }
}

.news4__content-left {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;

  .news4-title {
    font-size: 24px;
    font-weight: bold;
  }

  .news4-createTime {
    padding: 10px 0;
    font-size: 14px;
    color: #A6A6A6;
    border-bottom: 1px solid #D4D4D4;

    span:first-of-type {
      margin-right: 32px;
    }
  }

  .news4__p-1 {
    margin: 20px 0;

    line-height: 1.5;
  }

  .news4__p-2,
  .news4__p-3,
  .news4__p-4 {

    line-height: 1.5;
  }
}

.news4-subTitle {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0 8px 0;
}

.news4-subTitle-subTitle {
  margin: 10px 0;
}

.news4-content {
  margin-top: 15px;
  text-indent: 2em;
  line-height: 1.5;
}
.tiles{
	margin: 20px 0 10px 0;
}
</style>
